/*
Logo Colours
#d4a22a is Shaping body
#aa5500 is shaping outline
Futures is #ffffff
Silver is #9b9c9c
*/

  h1 {
    color: black;
    font-size: 48px;
    font-family: "Arial", Sans-serif;
    font-weight: 300;
    padding-top: 65px;
  }
  body{
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: "Arial", Sans-serif;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 10px;
  }
  .row{
    padding-bottom: 1%;
    padding-top: 2%;
  }
  p{
    text-align: center;
  }
  h5{
    text-align: center;
  }
  h1{
    text-align: center;
  }
  h2{
    text-align: center;
  }
  h3{
    text-align: center;
  }
  
  .carousel-item{
    max-height: 400px;
    max-width: auto;
  }
  .carousel-caption{
    color: black;
  }

img.modal{
  max-height: 400px;
    max-width: auto;
    height: 60%;
    width: auto;
}

.carCap{
background-color: rgb(0, 0, 0,0.4);
padding: 3px;
border-radius: 7px;
}

#hero{
  align-self: center;
  border-radius: 10px;
  max-width: 700px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1%;
}
#homeDescription{
  padding-top: 3%;
}

.image-container-pc {
  max-width: 353px !important;
  height: auto;
  border-radius: 10px;
  width: 100% !important;

}

.image-container-phone img {
  width: 75%;
  max-width: 365px;
  height: auto;
  margin-left: 12.5%;
  margin-bottom: 10px;
  border-radius: 10px;
  display: grid;
  grid-column: 1;
  grid-row: 1;
  align-items: center;
  justify-content: center;
}
.image-container-phone {
  display: grid;
  align-items: center;
  justify-content: block;
  grid-column: 1;
  grid-row: 2;
}

.image-container-pc img {
  width: 30%;
  max-width: 300px;
  height: auto;
  margin-right: 20px; /* Adjust the margin as needed */
  border-radius: 10px;
  display: grid;
  align-items: center;
  justify-content: block;
  grid-column: 1;
  grid-row: 2;
}

.text-container {
  flex: 1;
}

#phoneCarImg {
  width: 100% !important;
  height: auto;
}
#phoneCarImg {
  width: auto;
  height: auto;
}

iframe {
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 0px #ccc;
  -moz-box-shadow: 0px 0px 0px #ccc;
  margin-left: auto; 
  margin-right: 0;
}

#Insta1{
  width: 90%;
  max-width: 250px;
  min-width: 180px;
  height: auto;
  box-shadow: 2px 2px 4px #ccc;
  border-radius: 10px;
}

#Insta2{
  max-width: 70%;
  min-width: 120px;
  margin-left: 15% !important;
  height: auto;
  box-shadow: 2px 2px 4px #ccc;
  border-radius: 10px;
}