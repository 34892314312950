/*
Logo Colours
#d4a22a is Shaping body
#aa5500 is shaping outline
Futures is #ffffff
Silver is #9b9c9c
*/
.topnav {
  background-color: black;
  overflow: hidden;
  position:fixed;
  top:0;
  width: 100%;
}

/* Style the links inside the navigation bar */
.topnav a {
float: left;
color: #f2f2f2;
text-align: center;
padding: 7px 8px;
text-decoration: none;
font-size: 17px;
}
.topnav a:hover {
  background-color: #aa5500;
  color: black;
}

/* Right-aligned section inside the top navigation */
.topnav-right {
float: right;
}

.topnav img{
  max-width: 25%;
  height: auto;
  float:left;
}

#contact {
  color: #ffffff;
}

.offcanvas {
    background-clip: padding-box;
    background-color: #474747;
    top: 0;
    right:0;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    outline: 0;
    opacity:1;
    position: fixed;
    visibility: hidden;
    z-index: 2;
    height: 100%;
    overflow: hidden;
    width: 20%;
    max-width: 300px !important;
}

.offcanvas ul{
  list-style-type: none;
  color:#ffffff;
  list-style-type: none;
  
}



.link {
  color:#000000;
  text-decoration: none;
  font-size: 20px;
  padding: 1px;
  margin: 10px;

}

.link:hover{
  color:#d4a22a;
  text-decoration:double;
  padding: 1px;
  margin: 10px;
  font-weight: bold ;
}

.footer {
  position: relative;
  bottom: 10px;
}

#closeButton {
  width: auto;
  height: 6%;
  min-height: 45px;
  position:fixed;
  top: 10px;
  right:2px;
  padding: 10px;
  z-index: 1000;
}

#menuIcon {
  width: auto;
  height: 6%;
  min-height: 45px;
  position:fixed;
  
  right:2px;
  padding: 10px;
  z-index: 1000;
}
.classes {
  margin:10px;
  padding: 10px;
}

Button {
  background-color:#d4a22a;
  transition: 0.3s
}

Button:hover {
  background-color:#aa5500;
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000000;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.5);
}

.header-content {
  display: flex;
  align-items: center;
}

.header-text {
  position:fixed;
  top:10px;
  right:90px;
  
  z-index: 1000;
}

.empty-div {
  flex-grow: 1;
}

body {
  background-color: #000000;
  color: #ffffff;
  padding-top: 75px;
}
h5{
  text-align: center;
}
#desktopNavbar{
  width:30% !important;
}
#phoneNavbar{
  width:100% !important;
}
#desktopMenu{
  text-align: left;
  line-height: 250%;
}
#phoneMenu{
  text-align: center;
  font-size: 50px;
  padding: 1px;
  margin: 10px;
}